
import React from 'react';
import { AppProvider } from './context/AppContext'; // Import the provider
import Location from './pages/Location';
import Requestor from './pages/Requestor';
import Results from './pages/Results';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Footer } from './components/Footer';
//import { TpmoRequest } from './pages/TpmoRequest'
//import { TpmoResponse } from './pages/TpmoResponse'
//import { TpmoGenPdf } from './pages/TpmoGenPdf'

function App() {
  return (
    <div className="App">
    <div className="content">
    <AppProvider> {/* Wrap the component tree with the Provider */}
      <Router>
        <Routes>
          <Route path="/" element={<Location />} />
          <Route path="/location" element={<Location />} />
          <Route path="/requestor" element={<Requestor />} />
          <Route path="/results" element={<Results />} />
        </Routes>
      </Router>
    </AppProvider>
    </div>
    <Footer/>
    </div>
  );
}

export default App;
