import React from 'react'
import './Footer.css';

export const Footer = () => {
  return (
    <div className="Footer">
        <br/>
        Copyright &copy; Clinebell Ltd. v1.0.15
    </div>
  )
}
